#hero {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
  border-bottom: 0px;
  background: $white-color;
  font-weight: 400;
  color: $dark-blue-text;
  padding: 0rem 5.6rem;
  margin-bottom: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  // Set position sticky so the jumbotron stays fixed while you scroll.
  // position: sticky;

  // If you want to set a background image on the hero section, uncomment these with your custom image

  /* background: url("/src/assets/[your-image].png");
  background-position: center;
  background-size: cover; */

  @include respond(phone) {
    padding: 0rem $default-font-size;
  }

  .hero-title {
    font-size: 5.6rem;
    font-weight: 700;
    margin-bottom: 3.2rem;
    text-align: left;

    @include respond(tab-land) {
      font-size: 4rem;
    }
    @include respond(tab-port) {
      font-size: 3.6rem;
      text-align: center;
    }
    @include respond(phone) {
      font-size: 3.5rem;
      line-height: 1.5;
    }
    @include respond(phone-xs) {
      font-size: 2.8rem;
    }
  }

  .hero-cta {
    display: flex;

    @include respond(tab-port) {
      justify-content: center;
    }

    & a {
      font-size: 2.4rem;

      @include respond(phone) {
        font-size: 2rem;
      }
    }
  }
}

.container.photos {
  display: flex;
  width: 100vw;
}

.panel {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: min(550px, calc(185px + (550 - 185) * ((100vw - 320px) / (1600 - 320))));
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  flex: 0.5;
  margin: 10px;
  position: relative;
  transition: all 700ms ease-in, filter 0.5s ease-in-out;  /* 改进了动画过渡效果 */
  filter: blur(2px) grayscale(100%);  /* 同时应用模糊和黑白效果 */
  -webkit-filter: blur(2px) grayscale(100%);  /* 兼容旧版浏览器 */

  @include respond(phone) {
    height: 185px;
  }
}

.panel h3 {
  font-size: 24px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  margin: 0;
  opacity: 0;
  display: none; /* 默认不显示，不占用布局空间 */
  visibility: hidden; /* 初始设置为不可见 */
  transition: opacity 0.3s ease-in 0.4s; /* 透明度变化动画 */
}

.panel.active {
  flex: 5;
  background-size: 100% 100%; /* 点击后使背景图片完全填充div，但可能不保持比例 */
  filter: none;  /* 活动状态下移除模糊效果 */
  opacity: 1; /* 活动状态下完全不透明 */
  -webkit-filter: none;  /* 兼容旧版浏览器 */
}

.panel.active h3 {
  opacity: 1;
  display: block; /* 激活状态下显示，占用布局空间 */
  visibility: visible; /* 当.panel为active时，设置为可见 */
  transition: opacity 0.3s ease-in 0.4s;
}

.panel-overlay {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* 半透明黑色遮罩 */
  border-radius: 50px; /* 保持和panel同样的圆角 */
  transition: background-color 0.5s; /* 平滑过渡效果 */
}

.panel.active .panel-overlay {
  background: rgba(0, 0, 0, 0); /* 激活状态下遮罩颜色变透明 */
}
