#about {
  background-color: $primary-color;
  background-image: linear-gradient(
    135deg,
    $primary-color 0%,
    $secondary-color 100%
  );
  color: $white-color;
  height: 100%;
  border-top: 0px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
  padding-bottom: 10%;

  @include respond(tab-land) {
    height: 100%;
    -webkit-clip-path: none;
    clip-path: none;
  }

  .about-wrapper {
    @include respond(phone) {
      //padding-bottom: 5rem;
    }

    &__image {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;

      @include respond(tab-land) {
        height: 100%;
      }
      @include respond(tab-port-sm) {
        padding-bottom: 6.4rem;
      }
    }

    &__info {
      display: flex;
      height: 100%;
      justify-content: center;
      flex-direction: column;

      @include respond(tab-port-sm) {
        align-items: center;
      }

      &-text {
        text-align: left;

        @include respond(tab-port) {
          text-align: left;
        }
        @include respond(tab-port-sm) {
          text-align: center;
        }

        &--important {
          background: darken($secondary-color, 1%);
          display: inline-block;
          font-style: italic;
          padding: 0 0.3rem;
          margin: 0.3rem 0;
          line-height: 1.6;

          @include respond(phone) {
            display: inline;
            margin: 0;
            padding: 0;
            background: transparent;
            font-style: normal;
          }
        }
      }
    }
  }
}

.small-dark {
  color: $textColorDark !important;
}

.blog-container {
  border-radius: 10px;
  color: $blogCardContainerColor;
  background-color: $lightBackground1;
  box-shadow: 0 0 36px $lightBoxShadow;
  cursor: pointer;
  height: 200px;
}

.blog-title {
  color: $blogCardTitleColor;
  font-size: 1.375rem;
  line-height: 24px;
  font-weight: 700;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 14px;
  margin-top: 32px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.small {
  font-size: 1.063rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: $cardSubtitle;
  padding: 0px 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 0px;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: $secondary-color;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: $textColorDark;
  font-family: courier, sans;
}

.blog-card {
  display: block;
  position: relative;
  background-color: transparent;
  border-radius: 4px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  height: 100%;
}

.blog-card::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: $secondary-color;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.4s ease-out;
}

.blog-card:hover::before {
  transform: scale(30);
}

.blog-card:hover > .small {
  transition: all 0.3s ease-out;
  color: $blogCardHoverSmallColor;
}

.blog-card:hover > h3 {
  transition: all 0.3s ease-out;
  color: $darkBoxShadow2;
}

@media screen and (max-width: 480px) and (min-width: 300px),
(min-width: 925px) and (max-width: 1024px),
(min-width: 1405px) and (max-width: 1550px) {
  .blog-card:hover::before {
    transform: scale(30);
  }
}

@media screen and (min-width: 481px) and (max-width: 675px),
(min-width: 1025px) and (max-width: 1404px),
(min-width: 1551px) {
  .blog-card:hover::before {
    transform: scale(40);
  }
  .blog-card::before {
    transition: transform 0.45s ease-out;
  }
}

@media screen and (min-width: 675px) and (max-width: 860px) {
  .blog-card:hover::before {
    transform: scale(50);
  }
  .blog-card::before {
    transition: transform 0.5s ease-out;
  }
}

@media screen and (min-width: 860px) and (max-width: 924px) {
  .blog-card:hover::before {
    transform: scale(60);
  }
  .blog-card::before {
    transition: transform 0.5s ease-out;
  }
}

@media screen and (max-width: 480px) and (min-width: 300px) {
  .blog-title {
    font-size: 1.125rem;
  }
}

.blog-card-shadow {
  box-shadow: 1px 0px 20px $darkBoxShadow2;
}

.square {
  width: 440px;
  height: 430px;
  background: $textColorDark;
  border-radius: 4px;
  box-shadow: 0px 20px 50px $darkBoxShadow;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-top: 30px;
}

.square:hover {
  box-shadow: 0 20px 40px $lightBoxShadow;
}

.blog-header-text {
  font-size: 56px;
  font-weight: 400;
}

.blog-subtitle {
  text-transform: uppercase;
}

.blog-main-div > * {
  flex: 1;
  margin-bottom: 30px;
}

.blog-main-div {
  display: flex;
}

.blog-image-div > img {
  max-width: 100%;
  height: auto;
}

.blog-text-div {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 32px;
}

.subTitle {
  color: $subTitle;
}

/* Media Query */
@media (max-width: 1380px) {
  .blog-header-text {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .blog-header-text {
    font-size: 30px;
    text-align: center;
  }
  .blog-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
}

@media screen and (max-width: 480px) and (min-width: 300px) {
  .blog-text-div {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
